import { memo } from 'react';

import styled from 'styled-components';

import { cssVariables } from '@feather';
import { Merge } from '@feather/components/icons';

const Container = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  background-color: ${cssVariables('content-1')};
  margin-right: 6px;
`;

type Size = 'small';
const SIZE: {
  [size in 'small']: { containerSize: number; iconSize: number; borderRadius: number };
} = {
  small: {
    containerSize: 20,
    iconSize: 14,
    borderRadius: 6,
  },
};

type Props = {
  size: Size;
};
const SequenceIcon = memo(({ size }: Props) => {
  return (
    <Container
      css={`
        width: ${SIZE[size].containerSize}px;
        height: ${SIZE[size].containerSize}px;
        border-radius: ${SIZE[size].borderRadius}px;
      `}
    >
      <Merge size={SIZE[size].iconSize} color="white" />
    </Container>
  );
});

export default SequenceIcon;
