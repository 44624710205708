import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ClickSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.99976C8.68629 2.99976 6 5.68605 6 8.99976L6 14.9998C6 18.3135 8.68629 20.9998 12 20.9998C15.3137 20.9998 18 18.3135 18 14.9998L18 8.99976C18 5.68605 15.3137 2.99976 12 2.99976ZM4 8.99976C4 4.58148 7.58172 0.999756 12 0.999756C16.4183 0.999756 20 4.58148 20 8.99976L20 14.9998C20 19.418 16.4183 22.9998 12 22.9998C7.58172 22.9998 4 19.418 4 14.9998L4 8.99976ZM12.7138 6.29288C13.1043 6.6834 13.1043 7.31657 12.7138 7.70709L12.7067 7.71416C12.3162 8.10469 11.683 8.10469 11.2925 7.71416C10.902 7.32364 10.902 6.69047 11.2925 6.29995L11.2995 6.29288C11.6901 5.90235 12.3232 5.90235 12.7138 6.29288Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Click = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ClickSvg} {...props} />
));
Click.displayName = 'Click';
export default Click;
