import type { CSSProperties } from 'styled-components';
import styled, { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import { Body, Typography } from '@feather/typography';

import { defaultTransition } from './constants';

export const OptionListItem = styled.li<{ isHighlighted: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
  color: ${cssVariables('neutral-10')};
  white-space: nowrap;
  ${Body['body-short-01']};

  &[disabled] {
    cursor: not-allowed;
    color: ${cssVariables('neutral-5')};
  }

  ${(props) =>
    props.isHighlighted &&
    css`
      background: ${cssVariables('neutral-1')};
    `}

  ${(props) =>
    props.isSelected &&
    css`
      color: ${cssVariables('purple-7')};
      transition: ${defaultTransition.css};
    `}
`;

export const ErrorMessage = styled.div<{ width?: CSSProperties['width'] }>`
  margin-top: 4px;
  color: ${cssVariables('content-negative')};
  ${Typography['caption-01']};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;
