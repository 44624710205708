import type { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Downshift from 'downshift';
import type { PopperChildrenProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';

import * as Icons from '@feather/components/icons';
import { placeholder } from '@feather/mixins';
import cssVariables from '@feather/theme/cssVariables';
import type {
  DropdownItem,
  DropdownProps,
  DropdownSection,
  DropdownSectionWithSubheader,
  DropdownSize,
  DropdownVariant,
} from '@feather/types';
import { useOnChangeTrigger } from '@feather/utils/useOnChangeTrigger';

import { ScrollBar } from '../ScrollBar';
import { IconButton } from '../button';
import { DropdownMenuItemList } from './DropdownMenuItemList';
import { DropdownToggleButton } from './DropdownToggleButton';
import { ErrorMessage } from './components';
import { MENU_SCROLLBAR_VERTICAL_PADDING, defaultEmptyView, defaultItemToString, menuTransition } from './constants';
import { DropdownContext } from './dropdownContext';
import { DropdownMenuItemWithDropdownContext } from './dropdownMenuItem';
import { DropdownSubheader } from './dropdownSubheader';
import { generateToggleContentSizeStyle } from './styleGenerators';

const SearchInput = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px 0 16px;
  border-bottom: 1px solid ${cssVariables('neutral-3')};
  > input {
    width: 100%;
    height: 40px;
    border: none;
    color: ${cssVariables('neutral-10')};
    font-size: 14px;
    outline: none;
    -webkit-appearance: none;
    ${placeholder}
  }
`;

const SearchInputIconButton = styled(IconButton)`
  flex: none;
`;

type MenuRendererProps<T> = Pick<
  DropdownProps<T>,
  | 'isMenuScrollable'
  | 'items'
  | 'emptyView'
  | 'itemsType'
  | 'itemToString'
  | 'itemHeight'
  | 'listWidth'
  | 'itemWrapperStyle'
  | 'footerWrapperStyle'
> &
  Pick<PopperChildrenProps, 'scheduleUpdate'> & {
    maxHeight?: number;
  };

const ToggleContent = styled.div<{
  size: DropdownSize;
  variant: DropdownVariant;
}>`
  ${generateToggleContentSizeStyle}

  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Section = styled.div`
  & + & {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid ${cssVariables('neutral-3')};
  }
`;

const DropdownWrapper = styled.div<{ width: string | number }>`
  position: relative;
  width: ${(props) => {
    if (props.width) {
      if (typeof props.width === 'number') {
        return `${props.width}px`;
      }

      return props.width;
    }
    return null;
  }};
  border-radius: 4px;
  &:focus {
    border: 2px solid ${cssVariables('purple-7')};
  }
`;

const DropdownHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  border-bottom: 1px solid ${cssVariables('neutral-3')};
`;

const DropdownFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 4px;
  border-top: 1px solid ${cssVariables('neutral-3')};
`;

const WindowInnerComponent = styled.div`
  position: relative;
`;

const DropdownProvider = <T extends DropdownItem>({
  children,
  dropdownProps,
}: {
  dropdownProps: DropdownProps<T>;
  children: ReactNode;
}) => {
  const {
    selectedItem,
    initialSelectedItem,
    itemToString = defaultItemToString,
    onItemSelected,
    onChange,
    stateReducer,
    onStateChange,
  } = dropdownProps;
  const [inputValue, setInputValue] = useState('');

  const handleSelect = (item: T | null) => {
    if (onItemSelected) {
      onItemSelected(item);
    }
  };

  const handleChange = (item: T | null) => {
    if (onChange) {
      onChange(item);
    }
  };

  return (
    <Downshift
      selectedItem={selectedItem}
      initialSelectedItem={initialSelectedItem}
      itemToString={(item) => (item == null ? '' : itemToString(item))}
      onSelect={handleSelect}
      onChange={handleChange}
      inputValue={inputValue}
      stateReducer={stateReducer}
      onStateChange={onStateChange}
    >
      {(downshiftOptions) => (
        <DropdownWrapper {...downshiftOptions.getRootProps()} width={dropdownProps.width}>
          <DropdownContext.Provider
            value={{
              ...downshiftOptions,
              dropdownProps,
              setInputValue,
            }}
          >
            {children}
          </DropdownContext.Provider>
        </DropdownWrapper>
      )}
    </Downshift>
  );
};

const DropdownSections = <T extends DropdownItem>({ items }: { items: DropdownSection[] }) => {
  let index = -1;
  return (
    <>
      {items.reduce((result: ReactNode[], section: DropdownSection, sectionIndex: number) => {
        result.push(
          <Section key={sectionIndex}>
            {section.items.map((item) => {
              index++;
              return <DropdownMenuItemWithDropdownContext<T> item={item} index={index} key={index} />;
            })}
          </Section>,
        );
        return result;
      }, [])}
    </>
  );
};

const DropdownSectionsWithSubheader = <T extends DropdownItem>({
  items: sections,
  titleToString = defaultItemToString,
}: {
  items: DropdownSectionWithSubheader<T>[];
  titleToString: DropdownProps<T>['itemToString'];
}) => {
  let index = -1;
  return (
    <>
      {sections.map(({ title, items }, sectionIndex) => (
        <Fragment key={`section-${sectionIndex}`}>
          {title && (
            <DropdownSubheader isHighlighted={false} isSelected={false}>
              {titleToString(title)}
            </DropdownSubheader>
          )}
          {items.map((item, itemIndex) => {
            index++;
            return (
              <DropdownMenuItemWithDropdownContext<T>
                item={item}
                index={index}
                key={`section-${sectionIndex}-item-${itemIndex}`}
              />
            );
          })}
        </Fragment>
      ))}
    </>
  );
};

const DropdownToggle = <T extends DropdownItem>() => {
  const {
    getToggleButtonProps,
    selectedItem,
    itemToString = defaultItemToString,
    isOpen,
    dropdownProps,
  } = useContext(DropdownContext);
  const {
    toggleButtonRef,
    placeholder = '',
    size = 'medium',
    width,
    variant = 'default',
    toggleRenderer,
    toggleCustomRenderer,
    toggleButtonProps: toggleButtonPropsToOverride,
    toggleTheme,
    showArrow = true,
    disabled = false,
    readOnly = false,
    error,
    className,
    tooltipProps,
    selectedItemPrefix,
  } = dropdownProps as DropdownProps<T>;

  const toggleButtonProps = getToggleButtonProps({
    disabled: disabled || readOnly,
    onMouseDown: (event) => {
      event.currentTarget.style.boxShadow = '';
    },
    onFocus: (event) => {
      event.currentTarget.style.boxShadow = '';
    },
    onMouseUp: (event) => {
      event.currentTarget.style.boxShadow = 'none';
    },
    ...toggleButtonPropsToOverride,
  });

  const hasError = !!error;

  return (
    <Reference innerRef={toggleButtonRef}>
      {({ ref }) => {
        if (toggleCustomRenderer) {
          return toggleCustomRenderer({ selectedItem, isOpen, hasError, ref, toggleButtonProps });
        }

        const toggleProps = {
          ...toggleButtonProps,
          ref,
          toggleTheme,
          hasError,
          'aria-pressed': isOpen,
        };
        return (
          <DropdownToggleButton
            {...toggleProps}
            size={size}
            width={width}
            variant={variant}
            isPlaceholder={selectedItem == null}
            readOnly={readOnly}
            tooltipProps={tooltipProps}
            showArrow={showArrow}
            className={className}
          >
            {toggleRenderer ? (
              toggleRenderer({ selectedItemPrefix, selectedItem, isOpen, hasError })
            ) : (
              <ToggleContent size={size} variant={variant}>
                {selectedItemPrefix}
                {(selectedItem != null && itemToString(selectedItem)) || placeholder}
              </ToggleContent>
            )}
          </DropdownToggleButton>
        );
      }}
    </Reference>
  );
};

const WindowedMenu = <T extends DropdownItem>({
  items,
  itemHeight = 0,
  itemToString = defaultItemToString,
  listWidth,
}: { items: T[] } & Pick<DropdownProps<T>, 'itemHeight' | 'itemToString' | 'listWidth'>) => {
  const actualHeight = itemHeight * items.length;
  const Row = ({ index, style }) => {
    const item = items[index];
    return <DropdownMenuItemWithDropdownContext<T> key={itemToString(item)} item={item} index={index} style={style} />;
  };
  return (
    <FixedSizeList
      itemSize={itemHeight}
      width={listWidth}
      height={actualHeight > 320 ? 320 : actualHeight + MENU_SCROLLBAR_VERTICAL_PADDING * 2}
      itemCount={items.length}
      outerElementType={ScrollBar}
      innerElementType={WindowInnerComponent}
    >
      {Row}
    </FixedSizeList>
  );
};

const DropdownHeader = <T extends DropdownItem>() => {
  const { dropdownProps } = useContext(DropdownContext);
  const { header = null } = dropdownProps as DropdownProps<T>;

  if (!header) {
    return null;
  }
  return <DropdownHeaderContainer>{header}</DropdownHeaderContainer>;
};

const DropdownFooter = <T extends DropdownItem>() => {
  const { dropdownProps } = useContext(DropdownContext);
  const { footer = null, footerWrapperStyle } = dropdownProps as DropdownProps<T>;

  if (!footer) {
    return null;
  }
  return <DropdownFooterContainer style={footerWrapperStyle}>{footer}</DropdownFooterContainer>;
};

const MenuRenderer = <T extends DropdownItem>({
  isMenuScrollable,
  items,
  emptyView,
  itemsType = 'array',
  itemToString = defaultItemToString,
  scheduleUpdate,
  itemHeight,
  listWidth,
  itemWrapperStyle,
  maxHeight = 320,
}: MenuRendererProps<T>): ReactElement<MenuRendererProps<T>> => {
  useEffect(() => {
    scheduleUpdate();
  }, [items, scheduleUpdate]);

  if (isMenuScrollable && items.length > 0 && itemHeight && itemsType === 'array') {
    return (
      <WindowedMenu items={items as T[]} itemHeight={itemHeight} listWidth={listWidth} itemToString={itemToString} />
    );
  }

  const menuContent = (() => {
    if (items.length === 0) {
      return emptyView || defaultEmptyView;
    }
    switch (itemsType) {
      case 'section': {
        return <DropdownSections<T> items={items as DropdownSection[]} />;
      }
      case 'section-with-subheader': {
        return (
          <DropdownSectionsWithSubheader<T>
            items={items as DropdownSectionWithSubheader[]}
            titleToString={itemToString}
          />
        );
      }
      default: {
        return (items as T[]).map((item, index) => (
          <DropdownMenuItemWithDropdownContext<T>
            key={itemToString(item)}
            item={item}
            index={index}
            style={itemWrapperStyle}
          />
        ));
      }
    }
  })();

  return isMenuScrollable ? (
    <ScrollBar style={{ maxHeight }} options={{ wheelPropagation: false }}>
      {menuContent}
    </ScrollBar>
  ) : (
    <div style={{ padding: `${MENU_SCROLLBAR_VERTICAL_PADDING}px 0` }}>{menuContent}</div>
  );
};

const useIsOpen = () => {
  const { isOpen } = useContext(DropdownContext);
  const previousIsOpen = useRef(isOpen);

  useEffect(() => {
    previousIsOpen.current = isOpen;
  }, [isOpen]);

  return { isOpen, isOpenChanged: previousIsOpen.current !== isOpen };
};

const DropdownMenu = <T extends DropdownItem>() => {
  const [isTransitioned, setIsTransitioned] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    itemToString = defaultItemToString,
    getMenuProps,
    getInputProps,
    dropdownProps,
    inputValue,
    setInputValue,
  } = useContext(DropdownContext);
  const { isOpen, isOpenChanged } = useIsOpen();

  const timeoutRef = useRef(0);

  const {
    portalId,
    placement = 'bottom-start',
    modifiers,
    width,
    items = [],
    itemsType = 'array',
    positionFixed = false,
    emptyView,
    useSearch = false,
    searchPlaceholder,
    onSearchChange,
    onSearchKeyDown,
    isMenuScrollable,
    itemHeight,
    listWidth,
    itemWrapperStyle,
    listMaxHeight,
  } = dropdownProps as DropdownProps<T>;

  const handleIconButtonClick: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      setInputValue('');
    },
    [setInputValue],
  );

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isOpenChanged) {
      // Prevent setTimeout from being called on the first render
      return;
    }

    window.clearTimeout(timeoutRef.current);
    if (isOpen) {
      timeoutRef.current = window.setTimeout(() => {
        setIsTransitioned(true);
        searchInputRef.current?.focus();
      }, menuTransition.duration.milliseconds);
    } else {
      timeoutRef.current = window.setTimeout(() => {
        setIsTransitioned(false);
        setInputValue('');
      }, menuTransition.duration.milliseconds);
    }
  }, [isOpen, isOpenChanged, setInputValue]);

  useOnChangeTrigger(inputValue ?? '', onSearchChange);

  const popper = useMemo(() => {
    const { ref: menuPropsRef, ...menuProps } = getMenuProps({}, { suppressRefError: true });
    return (
      <Popper placement={placement} positionFixed={positionFixed} modifiers={modifiers} innerRef={menuPropsRef}>
        {({ ref, scheduleUpdate, style }) => {
          return (
            <DropdownMenuItemList
              {...menuProps}
              ref={ref}
              isOpen={isOpen}
              isOpenTransitionEnded={isTransitioned}
              width={width}
              style={style}
            >
              {isOpen && (
                <>
                  {useSearch && (
                    <SearchInput>
                      <input
                        ref={searchInputRef}
                        type="text"
                        placeholder={typeof searchPlaceholder === 'string' ? searchPlaceholder : 'Search'}
                        {...getInputProps({
                          onChange: (event) => setInputValue(event.target.value),
                          onKeyDown: (event) => {
                            onSearchKeyDown?.(event);
                          },
                        })}
                      />
                      <SearchInputIconButton
                        buttonType="secondary"
                        icon={inputValue ? Icons.Close : Icons.Search}
                        size="small"
                        onClick={handleIconButtonClick}
                      />
                    </SearchInput>
                  )}
                  <DropdownHeader />
                  <MenuRenderer<T>
                    isMenuScrollable={isMenuScrollable}
                    items={items}
                    emptyView={emptyView}
                    itemsType={itemsType}
                    itemToString={itemToString}
                    scheduleUpdate={scheduleUpdate}
                    itemHeight={itemHeight}
                    listWidth={listWidth}
                    itemWrapperStyle={itemWrapperStyle}
                    maxHeight={listMaxHeight}
                  />
                  <DropdownFooter />
                </>
              )}
            </DropdownMenuItemList>
          );
        }}
      </Popper>
    );
  }, [
    emptyView,
    getInputProps,
    getMenuProps,
    handleIconButtonClick,
    inputValue,
    isMenuScrollable,
    isOpen,
    isTransitioned,
    itemHeight,
    itemWrapperStyle,
    itemToString,
    items,
    itemsType,
    listWidth,
    modifiers,
    onSearchKeyDown,
    placement,
    positionFixed,
    searchPlaceholder,
    setInputValue,
    useSearch,
    width,
    listMaxHeight,
  ]);

  if (!portalId) {
    return popper;
  }

  const portalContainer = document.getElementById(portalId);
  return portalContainer && createPortal(popper, portalContainer);
};

export const Dropdown = <T extends DropdownItem>(dropdownProps: DropdownProps<T>) => {
  const { error, width } = dropdownProps;

  return (
    <Manager>
      <DropdownProvider<T> dropdownProps={dropdownProps}>
        <DropdownToggle<T> />
        <DropdownMenu<T> />
        {error && <ErrorMessage width={width}>{error}</ErrorMessage>}
      </DropdownProvider>
    </Manager>
  );
};

Dropdown.defaultProps = {
  isMenuScrollable: true,
};

Dropdown.stateChangeTypes = Downshift.stateChangeTypes;
