import type { MouseEventHandler, ReactNode } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { transitions } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';
import type { OverflowMenuItemInfo } from '@feather/types';
import { Body } from '@feather/typography';

type OverflowMenuItemProps = Pick<
  OverflowMenuItemInfo,
  'variant' | 'href' | 'target' | 'useReactRouterLink' | 'disabled'
> & {
  onClick: MouseEventHandler<HTMLElement>;
  $isPreviousItemDivider?: boolean;
  $isHighlighted?: boolean;
  itemProps?: any;
  children: ReactNode;
};

type MenuItemProps = Pick<OverflowMenuItemProps, '$isHighlighted' | '$isPreviousItemDivider' | 'disabled' | 'variant'>;

const menuItemCSS = (props: MenuItemProps) => css`
  position: relative;
  display: block;
  padding: 6px 16px;
  cursor: pointer;
  white-space: nowrap;
  ${Body['body-short-01']}
  text-decoration: none !important;
  transition: ${transitions({ properties: ['background', 'color'], duration: 0.2 })};

  color: ${cssVariables(props.variant === 'danger' ? 'red-5' : 'neutral-10')} !important;
  svg {
    fill: ${cssVariables(props.variant === 'danger' ? 'red-5' : 'neutral-10')} !important;
    transition: ${transitions({ properties: ['fill'], duration: 0.2 })};
  }

  ${props.$isHighlighted &&
  css`
    background-color: ${cssVariables('neutral-2')};
    ${props.variant === 'danger' &&
    !props.disabled &&
    css`
      background-color: ${cssVariables('red-5')};
      color: white !important;
      svg {
        fill: white !important;
      }
    `};
  `};

  &:hover {
    background-color: ${cssVariables('neutral-2')};
    ${props.variant === 'danger' &&
    !props.disabled &&
    css`
      background-color: ${cssVariables('red-5')};
      color: white !important;
      svg {
        fill: white !important;
      }
    `};
  }

  &:disabled,
  &[aria-disabled='true'] {
    background-color: white;
    cursor: not-allowed;
    color: ${cssVariables('neutral-5')} !important;
    svg {
      fill: ${cssVariables('neutral-5')} !important;
    }
  }

  ${props.$isPreviousItemDivider &&
  css`
    margin-top: 16px;
    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: -17px;
      height: 1px;
      margin: 8px 0;
      background-color: ${cssVariables('neutral-3')};
    }
  `}
`;

const MenuListItem = styled.li<MenuItemProps>`
  ${menuItemCSS}
`;
const MenuItemLink = styled.a<MenuItemProps>`
  ${menuItemCSS}
`;
const MenuItemReactRouterLink = styled(Link)<MenuItemProps>`
  ${menuItemCSS}
`;

export const OverflowMenuItem = ({
  children,
  variant,
  href,
  target,
  useReactRouterLink,
  disabled = false,
  onClick: onClickProp,
  $isPreviousItemDivider: isPreviousItemDivider,
  $isHighlighted: isHighlighted,
  itemProps,
}: OverflowMenuItemProps) => {
  const onClick = disabled ? undefined : onClickProp;
  if (href) {
    if (useReactRouterLink) {
      return (
        <MenuItemReactRouterLink
          to={disabled ? '' : href}
          target={target}
          variant={variant}
          $isPreviousItemDivider={isPreviousItemDivider}
          $isHighlighted={isHighlighted}
          onClick={onClick}
          disabled={disabled}
          {...itemProps}
        >
          {children}
        </MenuItemReactRouterLink>
      );
    }
    return (
      <MenuItemLink
        href={disabled ? '' : href}
        target={target}
        variant={variant}
        $isHighlighted={isHighlighted}
        onClick={onClick}
        disabled={disabled}
        $isPreviousItemDivider={isPreviousItemDivider}
        {...itemProps}
      >
        {children}
      </MenuItemLink>
    );
  }
  return (
    <MenuListItem
      variant={variant}
      $isHighlighted={isHighlighted}
      onClick={onClick}
      aria-disabled={disabled}
      $isPreviousItemDivider={isPreviousItemDivider}
      {...itemProps}
    >
      {children}
    </MenuListItem>
  );
};
